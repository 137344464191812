import uri from 'urijs';
import {
  createAction,
  USER_ID_TYPE_OWNER,
  USER_ID_TYPE_SITE_MEMBER,
  getIsDebugEnabled,
} from '@wix/communities-blog-client-common';
import { buildPaginationRequestParams, getTotalResults } from '../../services/pagination';

import { debug } from '../../services/debug';

export const FETCH_USER_COMMENTS_REQUEST = 'userComments/FETCH_REQUEST';
export const FETCH_USER_COMMENTS_SUCCESS = 'userComments/FETCH_SUCCESS';
export const FETCH_USER_COMMENTS_FAILURE = 'userComments/FETCH_FAILURE';

export const fetchProfileCommentsRequest = createAction(
  FETCH_USER_COMMENTS_REQUEST,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchProfileCommentsSuccess = createAction(
  FETCH_USER_COMMENTS_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchProfileCommentsFailure = createAction(
  FETCH_USER_COMMENTS_FAILURE,
  payload => payload,
  (payload, meta) => meta,
);

function fetchComments({ userIdType = USER_ID_TYPE_OWNER, id, page, pageSize }) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchProfileCommentsRequest({ userIdType, id }));

    const params = buildPaginationRequestParams(page, pageSize);
    const promise = request(uri(`/_api/comments/${userIdType}/${id}`).query(params), { parseHeaders: true });

    return promise
      .then(({ body = [], headers = {} }) =>
        dispatch(
          fetchProfileCommentsSuccess(body, {
            count: getTotalResults(headers),
            page,
          }),
        ),
      )
      .catch(error => {
        getIsDebugEnabled(getState()) && debug.error('fetchComments', error);
        dispatch(fetchProfileCommentsFailure({ userIdType, id }));
      })
      .then(() => promise);
  };
}

export function fetchCommentsBySiteMemberId({ siteMemberId, page, pageSize }) {
  return fetchComments({
    userIdType: USER_ID_TYPE_SITE_MEMBER,
    id: siteMemberId,
    page,
    pageSize,
  });
}
